import React, { useState } from 'react';
import { TextAreaField, TextField } from '../components/InputField';
import '../pages/ContactPage.css';

import {
	validate,
	validateEmail,
	validateName,
	validateMessage,
} from '../components/Validator';

import MainLayout from '../layout/MainLayout';

const ContactPage = () => {
	const [sent, setSent] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState(false);

	const [form, setForm] = useState({
		email: { value: '', error: '' },
		name: { value: '', error: '' },
		message: { value: '', error: '' },
	});

	const updateField = (fieldName, newValue) => {
		const formCopyForEdition = {...form}
		formCopyForEdition[fieldName] = {
			error: '',
			value: newValue,
		};
		setForm(formCopyForEdition);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const emailError = validateEmail(form.email.value);
		const nameError = validateName(form.name.value);
		const messageError = validateMessage(form.message.value);

		setForm((formCopyForEdition) => {
			const prevFormForEdition = {...formCopyForEdition};

			prevFormForEdition.email = {
				value: formCopyForEdition.email.value,
				error: emailError,
			};
			prevFormForEdition.name = {
				value: formCopyForEdition.name.value,
				error: nameError,
			};
			prevFormForEdition.message = {
				value: formCopyForEdition.message.value,
				error: messageError,
			};

			const updatedForm = {
				email: { value: prevFormForEdition.email.value, error: emailError },
				name: { value: prevFormForEdition.name.value, error: nameError },
				message: {
					value: prevFormForEdition.message.value,
					error: messageError,
				},
			};

			return updatedForm;
		});

		const isValid = validate(form);

		if (!isValid) {
			return;
		}

		setSent(true);

		fetch('/send', { method: 'POST', body: JSON.stringify({
			name: form.name.value, 
			email: form.email.value, 
			message: form.message.value}) 
		})
		.then(response => response.json())
		.then((result) => {
			if(result?.success) {
				setSuccess(true);
			}
		})
		.catch((body) => {
			setError(true);
		});
	};

	return (
		<MainLayout>
			<div className='contact-page'>
				<h1 className='contact-header'>Get in touch</h1>

				<div className='contact-layout'>

					<div className='left-col'>
						<div className='contact-box'>
							<p className='contact-description'>
								Thank you for considering my music therapy services.{' '}
							</p>
							<p className='contact-description'>
								Please feel free to reach out to me using the contact form.
								I'm here to answer any questions you may have and to discuss how I
								can support you through music therapy.
							</p>
						</div>

						<div className='contact-box'>
							<h2 className='contact-details-subheader'>Contact details</h2>
							<div><h3 className='contact-details-row-header'>e-mail:</h3> <a className='contact-link' href='mailto:afra.musictherapy@gmail.com'>afra.musictherapy@gmail.com</a></div>
							<div><h3 className='contact-details-row-header'>mobile:</h3> <a className='contact-link' href='tel:07481402902'>07481402902</a></div>
							<div><h3 className='contact-details-row-header'>LinkedIn:</h3> <a className='contact-link' href='https://www.linkedin.com/in/afra-jurkiewicz-6637174a/'>here</a></div>
						</div>

					</div>

					<div className='right-col'>
						<div className='contact-box'>
							
							{success && <h2 className='contact-details-subheader'>Thank you for your message!</h2>}
							
							{error && <h2 className='contact-details-subheader'>Oops! Something went wrong :(</h2>}
							
							{!sent && 
								(<form onSubmit={handleSubmit}>
									<h2 className='contact-details-subheader'>Contact Form</h2>
									
									<TextField
										name='name'
										type='text'
										label='Your name'
										value={form.name.value}
										error={form.name.error}
										onChange={updateField}
										autoFocus
									/>

									<TextField
										name='email'
										type='email'
										label='Your e-mail'
										value={form.email.value}
										error={form.email.error}
										onChange={updateField}
										autoFocus
									/>

									<TextAreaField
										name='message'
										label='Message'
										value={form.message.value}
										error={form.message.error}
										onChange={updateField}
										autoFocus
									/>

									<button onClick={handleSubmit} className='button-submit'>
										Submit
									</button>
								</form>)
							}
							
						</div>
					</div>
				</div>		
			</div>	
		</MainLayout>
	);
};

export default ContactPage;
