import React from 'react';
import { MenuItems } from './MenuItems';
import './Dropdown.css';
import { NavLink } from 'react-router-dom';

function Dropdown() {
	return (
		<ul className='dropdown-menu'>
			{MenuItems.map((item, index) => (
				<li className='dropdown-menu-item' key={index}>
					<NavLink to={item.path} className={`dropdown-menu-item-link `}>
						{item.title}
					</NavLink>
				</li>
			))}
		</ul>
	);
}

export default Dropdown;
