import React from 'react';
import MainLayout from '../layout/MainLayout';
import './TestimonialsPage.css';
import TestimonialText from '../components/Media/TestimonialsText';

function TestimonialsPage() {
	return (
		<MainLayout>
			<div className='testimonials-wrapper'>
				<div className='testimonials-box'>
					
					<div className='testimonials-header'><h1>Testimonials</h1></div>
					
					<p className='testimonial-description'>what others say about me</p>
				</div>
				<div className='testimonial-grid'>
					{TestimonialText.map((testimonial, index) => (
						<div key={index} className='testimonial-container'>
							<p className='testimonial-text'>{testimonial.content}</p>
							<p className='testimonial-role'>
								{testimonial.person} {testimonial.caption}
							</p>
						</div>
					))}
				</div>
			</div>
		</MainLayout>
	);
}

export default TestimonialsPage;
