import React from 'react';
import './MainLayout.css';
import { Navbar } from '../components/Navbar';
import Footer from '../components/Footer';

function MainLayout({ children }) {
	return (
		<>
			<Navbar></Navbar>

			<div className='page-content-wrapper'>{children}</div>
			<Footer></Footer>
		</>
	);
}

export default MainLayout;
