import React from 'react';
import MainLayout from './MainLayout';

function ContentLayout({ contentHeader, children }) {
	return (
		<MainLayout>
			
				<div className='page-content'>
					{contentHeader && <h2 className='content-header'>{contentHeader}</h2>}
					{children}
			</div>
		</MainLayout>
	);
}

export default ContentLayout;
