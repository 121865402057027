import React from 'react';
import './InputField.css';

const useFieldProps = (props) => {
	return {
		type: props.type,
		name: props.name,
		value: props.value,
		onChange: (event) => {
			const inputValue = event.target.value;
			const fieldName = event.target.name;
			props.onChange(fieldName, inputValue);
		},
	};
};

const InputField = (props) => {
	return (
		<div>
			<label  className='input-field-label' htmlFor={props.name}>{props.label}</label>
			{props.children}

			{props.error && <p className='error-message'>{props.error}</p>}
		</div>
	);
};

export default InputField;

export const TextField = (props) => {
	const inputProps = useFieldProps(props);
	return (
		<InputField {...props}>
			<input {...inputProps} />
		</InputField>
	);
};

export const TextAreaField = (props) => {
	const inputProps = useFieldProps(props);
	return (
		<InputField {...props}>
			<textarea {...inputProps} />
		</InputField>
	);
};
