import React, { useState } from 'react';
import MainLayout from '../layout/MainLayout';
import './GalleryPage.css';
import imagesData from '../components/Media/Images';

function GalleryPage() {
	const [slide, setSlide] = useState(0);

	const nextSlide = () => {
		setSlide(slide === imagesData.length - 1 ? 0 : slide + 1);
	};

	const prevSlide = () => {
		setSlide(slide === 0 ? imagesData.length - 1 : slide - 1);
	};

	return (
		<MainLayout>
			<div className='carousel'>
				<div className='arrows-container'>
					<i
						className='bi bi-arrow-left-circle-fill arrow arrow-left'
						onClick={prevSlide}
					></i>
					<i
						className='bi bi-arrow-right-circle-fill arrow arrow-right'
						onClick={nextSlide}
					></i>
				</div>
				{imagesData.map((item, idx) => (
					<div
						// className='carousel-slide'
						key={idx}
						className={slide === idx ? 'slide anim' : 'slide slide-hidden'}
					>
						<img
							src={item.src}
							className='slide-image'
							alt={item.alt}
							description={item.description}
						/>
						{slide === idx && (
							<p className='carouselText'>{item.description}</p>
						)}
					</div>
				))}
			</div>
		</MainLayout>
	);
}

export default GalleryPage;
