export const validateEmail = (email) => {
    if (!email) {
        return 'Email is required';
    } else if (!/^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/.test(email)) {
        return 'Invalid email format';
    }
    return '';
};

export const validateName = (name) => {
    if (!name) {
        return 'Name is required';
    } else if (name.length < 2) {
        return 'Name is too short';
    }
    return '';
};

export const validateMessage = (message) => {
    if (!message) {
        return 'Please type your message';
    } else if (message.length < 10) {
        return 'Message too short!';
    }
    return '';
};

export const validate = (form) => {
    const emailValidationResult = validateEmail(form.email.value);
    const nameValidationResult = validateName(form.name.value);
    const messageValidationResult = validateMessage(form.message.value);

    return (
        !emailValidationResult && !nameValidationResult && !messageValidationResult
    );
};
