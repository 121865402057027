import './Footer.css';

const Footer = () => {
	return (
		<div className='footer-wrapper'>
			<div className='footer'>
				<p className='footer-text'>copywright © </p>
				<p className='footer-text'>A.Jurkiewicz</p>
			</div>
		</div>
	);
};
export default Footer;
