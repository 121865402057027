const data = [
  
    {
        src: '/folk1.jpeg',
        alt: 'Image 2',
        description:'La Sonora Boreal at Allen Valleys Folk Festival',
    },
    {
        src: 'folk2.jpeg',
        alt: 'Image 3',
        description:'La Sonora Boreal at Lindisfarne festival',
    },
    
    {
        src: 'choir.jpeg',
        alt: 'Image 3',
        description:'Multilingual Choir at Whitley Bay festival',
    }



];


export default data