export const MenuItems = [
	{
		title: 'Music therapy',
		path: '/services/music-therapy',
		cName: 'dropdown-link',
	},

	{
		title: 'Piano teaching',
		path: '/services/piano-teaching',
		cName: 'dropdown-link',
	},
];

export default MenuItems;
