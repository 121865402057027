export const TestimonialText = [
	{
		content:
			"Afra was my mum's music therapist  for more than 18 months , visiting her around 6 times a month to sing with her and accompany her on the piano. These visits were great for my mum as they kept up her interest in music and Afra was brilliant at  helping her find new and old pieces to sing, developing a great rapport and understanding of my mum as her Alzheimer's progressed. I would thoroughly recommend her!",
		person: 'Fred',
	},
	{
		content:
			"Afra is an amazing tutor and focuses on teaching what you want to learn. I'd had previous instrument experience as a child and Afra really brought what I had learned back then to the forefront, getting me to remember elements I thought I'd forgotten and helping me get back to reading and playing music again. Her end-of-lesson duet/improv sessions are an excellent way to bring together what you've learned over the lesson and give you the confidence to know you're taking in what she's teaching!",
		person: 'James',
	},

	{
		content:
			"Afra taught my child piano from age 9 to 15. She always had the right balance between support and challenge that's needed to keep motivation up! She guided them through the process of Grade exams but was also happy to focus on more contemporary pieces when the teenage years hit. Afra is warm, encouraging and engaging. I highly recommend Afra to student's of all ages",
		person: 'Allison',
	},

	{
		content:
			"Afra has worked with my daughter for almost 6 years. She helped her develop a real love for music, (not just passing exams!) She got to know what my daughter needed to grow and was able to come alongside her in an individualized way to strengthen her confidence and skill. I particularly enjoyed listening to their improvisations, and when they would sing together. My daughter knew Afra believed in her and they also had fun. I would have no hesitation recommending Afra, she will help you reach your goals and teach you some Polish folk tunes too, if you like!",
		person: 'Anna'
	},
	{
		content:
			" loved having Afra as my piano teacher because she helped me love things I used to find hard like improvising. She let me try any piece I wanted and was always enthusiastic to try something new. She would push me when I needed it, and it paid off!  I'm so glad she was my first piano teacher!",
		person: 'E.',
	},
	{
		content:
			' Afra was an absolutely incredible inspiration to me and I feel so grateful to have been taught by her',
		person: 'R.',
	},
];

export default TestimonialText;
