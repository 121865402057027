import React from 'react';
import ContentLayout from '../layout/ContentLayout';
import './PianoPage.css';

function PianoPage() {
	return (
		<ContentLayout contentHeader={'Music therapy'}>
			<div className='musicTherapyImage'></div>
			
			<p className='content-paragraph'>
				Music therapy is a registered healthcare profession in which music is
				used as a way to help to break though the barriers caused by
				life-limiting illness, disability or social isolation.
			</p>

			<p className='content-paragraph'>
				{' '}
				It involves working in 1-1, small group or open group formats. As a
				music therapist trained in Nordoff-Robbins approach, I care about
				offering people access to music making opportunities and exploring the
				social, emotional and physical benefits of active involvement in playing
				instruments, singing and composing.{' '}
			</p>

			<p className='content-paragraph'>
				{' '}
				No previous experience in music making is necessary to access music
				therapy.{' '}
			</p>

			<p className='content-paragraph'>
				As a music therapist, I care about building communities through music
				and art, offering experiences of health, well-being and belonging. I
				have worked with people in various challenging life circumstances, using
				music as a tool for connection, communication and social interaction.
			</p>

			<p className='content-paragraph'>
				The many benefits of music therapy can include:
			</p>

			<ul className='pianoList'>
				<li>Increased confidence and self-esteem</li>
				<li>
					Music as a pathway for communication and social/emotional connection
				</li>
				<li>
					Music making as a new challenge leading towards personal growth
				</li>
				<li>Increasing the sense of identity and belonging</li>
				<li>Reducing anxiety and improving well-being</li>
			</ul>

			<p className='content-paragraph'>
				I am registered with HCPC and fully insured healthcare professional. If
				you are interested in exploring the potential of music therapy in your
				organisation or at home please do not hesitate to get in touch.
			</p>


			<span className='pianoPricingHeading'> Prices: </span>

			<ul className='pianoList'>
				<li>£50 / 1-1 session</li>
				<li>£75 / group session</li>
			</ul>
			
			<p className='pianoPricing'>Block booking concessions available. </p>
		</ContentLayout>
	);
}

export default PianoPage;
