import React from 'react';
import ContentLayout from '../layout/ContentLayout';
import './PianoPage.css';

function PianoPage() {
	return (
		<ContentLayout contentHeader={'Piano teaching'}>
			<div className='pianoImage'></div>
			<p className='content-paragraph'>
				I now accept new piano and music students from the area of South
				Herefordshire and Monmouthshire. Please get in touch to discuss the
				teaching plan for yourself or your child.
			</p>

			<p className='pianoPricingHeading'>Prices for 2024/25: </p>

		
			<ul className='pianoList'>
				<li>£35 - 1 hour tuition</li>
				<li>£30 - 45 mins tuition</li>
				<li>£25 - 30 mins tuition</li>
			</ul>
			
		</ContentLayout>
	);
}

export default PianoPage;
