// Navbar.js
import './Navbar.css';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Dropdown from './Dropdown';

export const Navbar = () => {
	const location = useLocation();
	const isServicesPath = location.pathname.includes('/services/');
	const [menuOpen, setMenuOpen] = useState(false);
	const [showDropdown, setShowDropdown] = useState(false);

	const toggleMenu = () => {
		setMenuOpen(!menuOpen);
		setShowDropdown(false);
	};

	const showDropdownOnHover = () => {
		setShowDropdown(true);
	};

	const hideDropdownOnLeave = () => {
		setShowDropdown(false);
	};

	return (
		<nav className='main-nav' onMouseLeave={hideDropdownOnLeave}>
			<div className='burger-wrapper'>
				<button
					className={`burger ${menuOpen ? 'open' : ''}`}
					onClick={toggleMenu}
				>
					<span className='burger-menu'></span>
					<span className='burger-menu'></span>
					<span className='burger-menu'></span>
				</button>
			</div>
			<ul className={`main-menu ${menuOpen ? 'open' : ''}`}>
				<li className='main-menu-item'>
					<NavLink
						className='main-menu-item-link'
						to='/about'
						onClick={() => setMenuOpen(false)}
					>
						About
					</NavLink>
				</li>
				<li className='main-menu-item' onMouseEnter={showDropdownOnHover}>
					<NavLink
						className='main-menu-item-link'
						to='/services'
						onClick={() => setMenuOpen(false)}
					>
						Services
					</NavLink>
					{(isServicesPath || showDropdown || menuOpen) && <Dropdown />}
				</li>
				<li className='main-menu-item'>
					<NavLink
						className='main-menu-item-link'
						to='/testimonials'
						onClick={() => setMenuOpen(false)}
					>
						Testimonials
					</NavLink>
				</li>
				<li className='main-menu-item'>
					<NavLink
						className='main-menu-item-link'
						to='/gallery'
						onClick={() => setMenuOpen(false)}
					>
						Gallery
					</NavLink>
				</li>
				<li className='main-menu-item'>
					<NavLink
						className='main-menu-item-link'
						to='/contact'
						onClick={() => setMenuOpen(false)}
					>
						Contact
					</NavLink>
				</li>
			</ul>
		</nav>
	);
};

export default Navbar;
