import React from 'react';
import ContentLayout from '../layout/ContentLayout';
import './AboutPage.css';

function AboutPage() {
	return (
		<ContentLayout contentHeader={'About me'}>
			<div className='about-image'></div>

			<p className='content-paragraph'>
				My name is Afra and I am passionate about working with music and people. I studied
				music at Newcastle University and University of Sao Paulo, Brazil. Later
				on, I trained as a music therapist at Nordoff-Robbins centre in London
				and worked across the North East of England since 2018. I moved to
				Herefordshire in 2024 in search of new stories and adventures.
			</p>

			<p className='content-paragraph'>
				As a music therapist, I care about building communities through music
				and art, offering experiences of health, well-being and belonging. I
				have worked with people in various challenging life circumstances, using
				music as a tool for connection, communication and social interaction.
			</p>

			<p className='content-paragraph'>
				As a community musician, I have led community choirs and worked with
				London-based Groundswell Arts on Sing my Story project, writing bespoke
				songs for children and their families.
			</p>

			<p className='content-paragraph'>
				I have more than a decade of experience as a music teacher both
				privately and in music schools, including The Music House for Children
				in London. I was trained as a classical pianist in a rigorous Eastern
				European tradition. Having experienced severe performance anxiety
				throughout my school years, as a teacher I strive to make music
				accessible to children and help them grow a fulfilling connection with
				music based on exploration and curiosity and built on individual's
				strengths and resources.
			</p>

			<p className='content-paragraph'>
				I also help adults grow confidence to explore their relationship with
				music. It often happens that people leave the idea of playing
				instruments behind due to busy life and careers. I enjoy helping adults
				to bring musicking back in their lives.
			</p>

			<p className='content-paragraph'>
				Outside of work, I enjoy playing music with friends, reading, gardening
				and wild swimming.
			</p>
		</ContentLayout>
	);
}

export default AboutPage;
