import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import TestimonialsPage from './pages/TestimonialsPage';
import AboutPage from './pages/AboutPage';
import ContactPage from './pages/ContactPage';
import GalleryPage from './pages/GalleryPage';
import PianoPage from './pages/PianoPage';
import MusicTherapyPage from './pages/MusicTherapyPage';

import {
	createBrowserRouter,
	RouterProvider,
	Navigate,
} from 'react-router-dom';

const router = createBrowserRouter([
	{
		path: '/',
		element: <Navigate to='/about' replace />,
	},
	{
		path: 'testimonials',
		element: <TestimonialsPage />,
	},
	{
		path: 'about',
		element: <AboutPage />,
	},

	{
		path: 'gallery',
		element: <GalleryPage />,
	},
	{
		path: 'contact',
		element: <ContactPage />,
	},
	{
		path: 'services',
		children: [
			{
				index: true,
				element: <Navigate to='/services/music-therapy' replace />,
			},
			{
				path: 'piano-teaching',
				element: <PianoPage />,
			},
			{
				path: 'music-therapy',
				element: <MusicTherapyPage />,
			},
		],
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<RouterProvider router={router} />);
